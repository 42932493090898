<template>
  <div id="page-member-control-members-tab">
    <Confirm
      :title="$t('MEMBER.CONFIRM_DELETE')"
      :message="$t('MEMBER.CONFIRM_DELETE_MESSAGE')"
      ref="confirmModal"
      @cancel="$refs['confirmModal'].hide()"
      @confirm="confirm_delete_member"
    />

    <SelectPeriodAndCompanyModal
      ref="select-period-and-company-modal"
    />

    <MemberEditorCompactModal
      ref="member-editor-compact-modal-tab"
      :member_id="local_member_id"

      @created="member_created"
      @updated="member_updated"
      @deleted="member_deleted"
    />

    <MembersTable
      ref="membersTable"
      @member_selected="member_selected"
      @create_member_clicked="create_member_clicked"
      @delete="delete_member_clicked"
    />

  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

// Table of members
import MembersTable from '@/view/pages/ml/members_control/MembersTable.vue';
import SelectPeriodAndCompanyModal from '@/view/components/SelectPeriodAndCompanyModal.vue';

export default {
  name: 'member-control-members-tab',
  mixins: [ toasts ],
  props: ['member_id'],
  emits: ['deleted'],
  components: {
    MembersTable,
    SelectPeriodAndCompanyModal
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings'])
  },
  mounted() {
    if (this.member_id) {
      this.local_member_id = this.member_id;
    }
  },
  data() {
    return {
      local_member_id: null
    };
  },
  watch: {
    member_id(new_val) {
      this.local_member_id = new_val;
    },
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {
    create_member_clicked() {
      this.local_member_id = null;
      this.$refs['member-editor-compact-modal-tab'].show();
      this.$refs['select-period-and-company-modal'].show();
    },

    delete_member_clicked(member_id) {
      this.local_member_id = member_id;
      this.$refs['confirmModal'].show();
    },

    async confirm_delete_member() {

      try {

        const res = await axios.delete(`/member/${this.local_member_id}`);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.DELETED'));
          this.local_member_id = null;
          this.$refs['membersTable'].refresh();
        }

      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_DELETE_MESSAGE'));
      }

    },

    member_selected(member_id) {
      this.local_member_id = member_id;
      this.$refs['member-editor-compact-modal-tab'].show(member_id);
    },
    member_created(member) {
      this.$refs['membersTable'].refresh();
    },
    member_updated(member) {
      this.$refs['membersTable'].refresh();
    },
    member_deleted(member_id) {
      this.$emit('deleted', member_id);

      this.$refs['membersTable'].refresh();
    }
  }
};
</script>
