<!--

  Confirm that the user creates a record within the created period and company.

-->
<template>
  <div class="container">

    <memlist-modal
      style="z-index: 9999;"
      :title="$t('PERIOD.SELECT_PERIOD_AND_COMPANY')"
      ref="edit-form"
      >

        <m-row>
          <m-col cols="12">

            <memlist-select-input2
              id="period_id"
              name="period_id"
              :title="$t('PERIOD.PERIOD')"
              layout="vertical"
              validation_type="TEXT"
              v-model="item.period_id"
              :items="period_options"
              :invalid_text="''"
              :info_text="''"
            />
            
          </m-col>
        </m-row>

        <m-row>
          <m-col cols="12">
            <memlist-select-input2
              id="company_id"
              name="company_id"
              :title="$t('COMPANY.COMPANY')"
              layout="vertical"
              validation_type="TEXT"
              v-model="item.company_id"
              :items="company_options"
              :invalid_text="''"
              :info_text="''"
            />
            
          </m-col>
        </m-row>

        <m-row v-if="shop_item_options.length > 0">
          <m-col cols="12">
            <memlist-select-input2
              id="shop_item_id"
              name="shop_item_id"
              :title="$t('SHOP_ITEM.NAME')"
              layout="vertical"
              validation_type="TEXT"
              v-model="item.shop_item_id"
              :items="shop_item_options"
              :invalid_text="''"
              :info_text="''"
            />
            
          </m-col>
        </m-row>

        <RightModalSaveAndCloseButtons
          :text="$t('COMMON.SAVE')"
          :spin="true"
          class="mt-4"
          @clicked="on_submit"
          @close="$refs['edit-form'].hide()"
          ref="saveButton"
        />

    </memlist-modal>


  </div>

</template>


<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { mapGetters } from 'vuex';
import store from '@/core/services/store';
import axios from 'axios';

import {
  SET_COMPANY_ID,
  SET_PERIOD_ID,
  SET_SHOP_ITEM_ID
} from '@/core/services/store/common.module';

export default {

  name: 'SelectPeriodAndCompanyModal',
  props: ['member','items','company','creating_member'],
  mixins: [ toasts ],
  components: {
    
  },
  watch: {
    currentCompanyId() {
      this.item.company_id = this.currentCompanyId;
    },
    currentPeriodId() {
      this.item.period_id = this.currentPeriodId;
    },
    'item.company_id': {
      handler(newVal) {
        if (newVal) {
          this.fetch_shop_items(newVal);
        } 
      },
      immediate: true
    }

  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    is_mobile() {
      return is_mobile();
    },
    period_options() {
      return this.periods.map(( item ) => ( { text: item.name, value: item.id }));
    },
    company_options() {
      if (!this.companies) {
        return [];
      }

      return this.companies.map((item) => ({ text: item.name, value: item.id }));
    },
    
  },

  methods: {
    show() {
      this.$refs['edit-form'].show();
    },

    async fetch_shop_items(company_id) {
      if (!company_id) {
        this.shop_item_options = [];
        return;
      }

      const res = await axios.post(`/shop_item/pagination`, {
        options: {
          company_ids: [company_id]
        }
      });

      if (res.status === 200) {
        this.shop_item_options = res.data.map((item) => ({ text: item.name, value: item.shop_item_id }))
      }
      else {
        this.shop_item_options = [];
      }

    },

    on_submit() {
      if (this.currentCompanyId !== this.item.company_id) {
        store.dispatch(SET_COMPANY_ID, this.item.company_id);
      }

      if (this.currentPeriodId !== this.item.period_id) {
        store.dispatch(SET_PERIOD_ID, this.item.period_id);
      }

      store.dispatch(SET_SHOP_ITEM_ID, this.item.shop_item_id);

      this.$refs['edit-form'].hide();
    },

  },

  mounted() {

    this.item.period_id = this.currentPeriodId;
    this.item.company_id = this.currentCompanyId;

  },

  data() {
    return {
      item: {
        period_id: null,
        company_id: null
      },
      shop_item_options: []
    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
